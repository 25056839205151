.comsion{
    display: flex;
    align-items: center;
    /* border-radius: 10px; */
    border: 1px solid #C9D3DF;
    background: #ffff;
    outline: none;
    width: 503px;
    border-radius: 100px;
}
.comsion-serch{
    /* width:598px; */
    width: 441px;
    height:45px;
    outline: none;
    border: none;
    background: #ffff;
    border-radius: 10px;
    text-indent: 10px;
}

@media screen and (max-width:576px) {
    .comsion{
        width: 279px;
        
    }
    .comsion-serch {
        width: 85%;
    }
    .same-icon-size{
        width: 20px;
        /* height: 20px; */
    }

}