.main-payment-box {
  padding: 20px;
  margin-left: -20px;
  margin-top: 30px;
}

.commoinsion-card-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

.commoinsion-card-col {
  flex: 0 1 269px;
  max-width: 283px;
  min-width: 250px;
  box-sizing: border-box;
}

.contnt-mng {
  background: #fff;
  box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.08);
  /* border: 1px solid #C9D3DF; */
  border-radius: 10px;
  border: none;
 min-height: 135px;
 height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .card-body {
  padding: 20px;
} */

.totl-trnsc {
  color: #000;
  font-family: "Maven Pro";
  font-size: 16px;
  font-weight: 400;
}

.trns-text {
  color: #F33;
  font-family: "Maven Pro";
  font-size: 30px;
  font-weight: 600;
  width: 200px;
  
}
.settle-once{
  width: 207px;
height: 55px;
border-radius: 100px;
border: none;
color: #FFF;
background-color: #F33;
font-family: 'Maven Pro';
}
.settle-btn{
  width: 122px;
height: 38px;
border-radius: 100px;
color: #FFF;
background-color: #465CD9 !important;
border: none;
font-family: 'Maven Pro','sanss serif';
/* padding-top: 20px !important; */
  }
  .table .commision-table-data td{
    padding-top: 20px;
  }
.serchbar-settllebtn{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 80px;
}
.all-breakcrum{
  color: #222;
    font-family: "Maven Pro";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.All-color-breakcurm{
  color: #F33;
  text-decoration: none;
}
.All-color-breakcurm:hover{
  color: #F33;
}

/* .custom-dropdown-4{ */
  /* position: relative;
  top: calc(100% + 5px); */
  /* left: 46rem; */
  /* margin-top: -105px;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  /* padding: 5px; */
  /* width: 222px; */
  /* margin-top: -23rem; */
  /* margin-left: 417px; */
/* } */


.dropdown-mrgn{
margin-top: -16px;
}
.dropdown-item-4{
  text-align: right;
  font-size: 16px;
  padding: 20px;
}
@media screen and (max-width:992px) {
  .commoinsion-card-row{
    justify-content: center;
  }
  
}
@media screen and (max-width: 768px) {
  .commoinsion-card-col {
      flex: 1 1 100%;
      max-width: 100%;
  }

  .totl-trnsc {
      font-size: 14px;
  }

  .trns-text {
      font-size: 24px;
    
  }
  .main-payment-box{
    margin:auto;
    /* width:100%; */
  }
 
  .serchbar-settllebtn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
   .settle-once{ 
    width: 150px;
    height: 42px;
    margin-top: 10px;
   }
   .all-breakcrum{
    font-size: 15px;
    margin-bottom: 12px;
  }
}

@media screen and (max-width:480px) {
  .all-breakcrum{
    font-size: 15px;
    margin-bottom: 12px;
  }
  
}

