.padding-table-columns{
    margin-top: 50px;
  }
  .table-responsive{
    /* background: #FFF;
    box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.08); */


  }
  .table-responsive1{
    margin-top: 51px;
    /* box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.08); */


    /* overflow-x: scroll; */
  }
  .yellow-star{
    color: rgb(255, 196, 0);
  }
  th{
    padding: 0px;
  }
  .table-responsive1 tbody tr {
    /* border-bottom: 1px solid black; */
    padding-top: 10px !important;
    /* margin: 50px; */
  }
  .table-responsive1 thead tr {
    border-bottom: 1px solid rgb(219, 219, 219);
  }
  .table-heading {
    color: #222;
  
  font-family: "Maven Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
    text-align: left !important;
  }
 .td-design td {
    text-align: left !important;
  }
  .td-design{
    color: #000;
  
  font-family: "Maven Pro";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  }
  
  
  
  @media  (max-width: 500px)  {
  .td-design{
    font-size: 8px;
  }
  }
  
  
  @media (min-width: 576px) and (max-width: 1200px)  {
    .td-design {
        font-size: 16px;
    }
  }


  /* /* CommonReview.css */


.outr-cont {
  margin-top: 55px;
}

.listof-user {
  margin-top: 45px;
  color: #222;
  font-family: "Maven Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.drop-down {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: #f9f9f9;
  color: black;
  padding: 8px 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content button:hover {
  background-color: #ddd;
}

.drop-down:hover .dropdown-content {
  display: block;
}

.drop-down:hover .dropbtn {
  background-color: #f1f1f1;
}

.review-heding {
  background-color: white;
  text-align: justify;
}

.review-bdy {
  text-align: left;
}
  