.nav-link {
  color: black;
}

.nav-link:hover {
  color: #000;
}

.nav-link.active {
  font-weight: bold;
  color: #f33;
}

.nav-tabs {
  margin-top: 40px;
  border-bottom: none !important;
}

/* .nav-tabs .nav-link.active {
  border-color: #fff;
} */
.all-ticket-section {
  cursor: pointer; /* Pointer cursor for in-progress tickets */
}
.btm-line {
  border: 2px solid #f33;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: none !important;
  background-color: none !important;
  border-color: none !important;
}
.all-ticket-section {
  max-width: 1073px;
  min-height: 200px;
  width: 95%;
  height: auto !important;

  flex-shrink: 0;
  /* background-color: #f2f2f2; */
  background-color: #fff;
  box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  margin-top: 48.5px;
  border-radius: 10px;
  padding: 20px;
}

.wraper1 {
  display: flex;
  justify-content: space-between;
  width: 40%;
  margin-top: 18px;
  flex-wrap: wrap;
}

.ticket-title {
  color: #000;
  font-family: "Maven Pro";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ticket-content {
  width: 94%;
  color: #000;
  font-family: "Maven Pro";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 16px;
  word-wrap: break-word;
}

.ticket-priority {
  display: flex;
  width: 60%;
  justify-content: space-between;
  margin-top: 30px;
  /* margin-bottom: 39px !important; */
  flex-wrap: wrap !important;
}

.high p span {
  color: #465cd9;
  font-family: "Maven Pro";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.in-progress {
  color: #ffba00;
}
.completed {
  color: #00992b;
}

.right-turn-logo {
  transform: rotate(90deg);
}

.input-send-btn {
  display: flex;
  align-items: baseline;
  /* justify-content: space-between; */
}
.send-reply {
  border-radius: 10px;
  height: 50px;
  border: 1px solid rgb(113, 113, 113);
  flex: 1;
  text-indent: 10px;
}
.off-canvas {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow-y: auto;
  transition: transform 0.3s ease;
  height: 100vh;
  border: 1px solid black;
}

.off-canvas-content {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.off-canvas.show {
  transform: translateX(0);
}

.off-canvas.hide {
  transform: translateX(100%);
}

.sd-bar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sd-bar > p {
  color: #f33;
  font-weight: bold;
  font-family: "Maven Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.sd-bar-mail {
  color: #707070;
}
.prioty-status {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 21px;
  align-items: baseline;
}

.statusss {
  display: flex;
  align-items: baseline;
}

.statusss p {
  margin-right: 10px;
}

.dropdown {
  flex-grow: 1;
}

.lorem-eps-text {
  font-family: "Maven Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 6px;
}

.long-txt {
  font-family: "Maven Pro", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 7px;
}

.sde-bar-box {
  width: 100%;
  max-width: 400px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #a0a0a0;
  margin-top: 15px;
  height: 614px;
  overflow-y: scroll;
}

.side-bar-btnn {
  width: 16%;
  /* max-width: 400px; */
  height: 50px;
  border-radius: 40px;
  border: none;
  text-align: center;
  border-radius: 10px;
  margin-top: 18px;
  background-color: #f33;
  color: #fff;
}

@media (max-width: 576px) {
  .off-canvas {
    width: 100%;
    max-width: none;
  }
}

@media (max-width: 768px) {
  .all-ticket-section {
    /* width: 100%; */
    width: 94%;
    height: 200px;
    font-size: 12px;
    /* overflow-x: scroll; */
  }
}

.chat-message {
  margin-bottom: 10px;
  clear: both;
}

.sent {
  float: right;
  background-color: #dcf8c6;
  border-radius: 10px;
  padding: 8px 12px;
}

.received {
  float: left;
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 8px 12px;
}
#myTabContent{
  margin-bottom: 20px;
}