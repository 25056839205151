.benfits-contnr{
    margin-top: 40px;
    background: #FFF;
    box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 25px;
}
.benfits p{
    color: #4B4B4B;

font-family: "Maven Pro";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.benfits ul li{
    color: #919191;

font-family: "Maven Pro";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
opacity: 0.5;
}