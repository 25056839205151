.bckimg {
  background-image: url("../CommonComp/Assests/login.jpg");
  background-size: cover;
  background-position: center;
}
.custom-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 750px;
  height: 668px;
  padding: 20px;
  border: 1px solid rgb(200, 199, 199);
  border-radius: 10px;
  margin: auto;
}

.crd-bdy {
  width: 100%;
}


.verify {
  text-align: center;
  color: #343434;
  margin-top: 100px;
  font-family: "Maven Pro";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0;
}

.code-to-Email {
  text-align: center;
  margin-top: 10px;
  color: #4b4b4b;
  font-family: "Maven Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 14px;
}

.otp-code {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 80px;
}

input[type="tel"] {
  width: 60px !important;
  height: 60px !important;
  border-radius: 10px;
  background-color: #fff;
  border: none;
  border-radius: 100px;
  border: 0.91px solid #e9ecef;
}

.otp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 60px;
  background-color: #efefef;
  border-radius: 8px;
}

.otp-and-newcode {
  text-align: center;
  margin-top: 16px;
  color: #4b4b4b;
  font-family: "Maven Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.new-code {
  vertical-align: 5px;
  text-align: center;
  color: #4b4b4b;
  font-family: "Maven Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

/* .containerss{
  width: 338px;
} */

.button {
  margin-top: 42px;
 
  border-radius: 124px;
  color: #000;
  font-family: "Maven Pro", "sans-serif";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
 
  background: #ffcc42;
  height: 50px;
  outline: none;
  border: none;
}

.sendotpforget {
  width: 100%;
  height: 50px !important;
  border-radius: 10px;
  border: none; 
  background: #fff;
  text-align: center;
  color: #343434;
  font-family: "Maven Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 40px;

  border-radius: 124px;
  font-weight: 600;
  background: #ffcc42;
}
.custom-logo-verify-page {
  position: absolute;
  margin-top: -87px;
  width: 150px;
}
.custom-container-verify-page {
  width: 520px;
  height: 450px;
  max-width: 100%;
  max-height: auto;
 
  padding: 20px;
  border-radius: 50px !important;
}
.code-verifications-text {
  font-weight: 700;
  font-size: 35px;
}


/* *forget pasa page designs */
.forgetps {
  font-family: "Maven pro";
  background-image: url("../../components/CommonComp/Assests/login.jpg");
  background-size: cover;
}
.forget-your-pass {
  font-weight: bold;
  font-size: 25px;
}
.custom-container1 {
  padding: 12px !important;
  width: 530px;
  height: 420px;
  /* max-width: 100%; */
  max-height: auto;
  border-radius: 50px !important;
}

.custom-logo {
  position: relative;
  margin-top: -110px;
  width: 150px;
}
.custom-logo1 {
  position: relative;
  margin-top: -73px;
  width: 140px;
}

.custom-input {
  border: none;
  outline: none;
}

.custom-logo-verify1 {
  position: absolute;
  margin-top: -84px;
  width: 150px;
}



@media screen and (max-width: 1200px) {
  .custom-container-verify-page {
    padding: 20px !important;
   
  }
  .custom-container1 {
    width: 500px;
    height: auto;
    
  }
  .custom-logo1 {
    position: relative;
    margin-top: -72px;
    width: 150px;
  }
}
@media (max-width: 320px) {
  .custom-card {
    width: 100%;
  }
  .otp-code {
    margin-top: 50px;
  }

  input[type="tel"] {
    width: 40px !important;
    height: 40px !important;
  }

  .otp {
    height: 40px;
    width: 50px;
  }
  .containerss {
    width: 220px;
  }
  .phone-email-text {
    font-size: 14px !important;
  }
}

@media screen and(max-width: 576px) {
  .custom-container-sign {
    padding: 4px !important;
    border-width: 3px;
  }

  .forgetPassword {
    font-size: 14px;
  }
  .custom-logo1 {
    margin-top: -59px !important;
  }
  .custom-input-container {
    padding: 4px !important;
  }
  .custom-container1 {
    padding: 0px !important;
  }
  .request-new-code-container {
    width: 69% !important;
  }
  .custom-logo-verify1 {
    margin-top: -80px;
  }
}

@media screen and (max-width: 767px) {
  .custom-logo-verify1 {
    margin-top: -69px;
  }
  .code-verifications-text {
   
    margin-top: 25px !important;
    font-size: 32px;
  }
}


