
  .algn-top-bar {
    display: flex;
    justify-content: space-between;
      
    flex-wrap: wrap;
    align-items: center;
  }
 

  thead{
    border-radius: 10px;
    background: #D9D9D9;
}
/* .table-responsive {
    overflow-x: auto;
  } */
  .table th {
    text-align: center;
  }
  /* .drp-bttn{
    height: 20px;
    width: 20px;
  } */
  .anchr-clik{
    /* text-decoration: none; */
     color: #000;;
     cursor: pointer;

  }
  .tbl-design{
    border-radius: 10px;
    background-color: #FFF;
    box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.08);
    
  }
  .anchr-clik:hover{

    color:#000
  }
  .tbpading td{
    padding-top: 20px !important;
    text-align: center;
  }
  th{
    border: 10px;
    padding: 20px;
  }
  tr{
    /* padding: 20px; */
    margin-top: 20px;
  }
  .table-header th {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .bordr-drp{
    border-color: #000;
  }
  .dropdown-menu li{
    margin-top:8px;
   
    
  }
  .table-header th:last-child {
    border-top-right-radius: 0px;
  
  }
  
  .table-header th:first-child {
    border-top-left-radius: 0px;
  }
  .table tbody tr td {
    text-align: center;
  }
  table.rounded-corners thead tr:first-child th:first-child {
    border-top-left-radius: 10px;
   }
   
   table.rounded-corners thead tr:last-child th:first-child {
    border-bottom-left-radius: 10px;
   }
   
   table.rounded-corners thead tr:first-child th:last-child {
    border-top-right-radius: 10px;
   }
   
   table.rounded-corners thead tr:last-child th:last-child {
    border-bottom-right-radius: 10px;
   }
   
   table.rounded-corners tbody tr:first-child td:first-child {
    border-top-left-radius: 10px;
   }
   
   table.rounded-corners tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
   }
   
   table.rounded-corners tbody tr:first-child td:last-child {
    border-top-right-radius: 10px;
   }
   
   table.rounded-corners tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
   }

  /* ************* */
 .dropdown-item{
  cursor: pointer;
 }
  
 @media screen and (max-width:767px) {
  .table-wrapper-bartender{
    margin-right: 10px !important;
  }
  .tbl-strucutre-design {
    margin-right: 5px !important;
  }
 }




