
.home-route{
    margin-top: 69px;
}
.setting-crd-design{
    margin: 69px 0 0 -8px;
  
}
.settting-design{
    width: 355px;
    height:250px;
    color: #000;
text-align: center;
font-family: "Maven Pro";
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.settting-design> card{
    color: #000;
    text-align: center;
    font-family: "Maven Pro";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.nav-link{
text-decoration: none;
}