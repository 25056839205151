.notification-list {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 52px;
  display: flex;
  justify-content: space-between;
  width: 80%;
  /* width: 900px; */
  flex-wrap: wrap;
  align-items: center;
}
.notiftctn {
  /* margin-top: 10px !important; */
  border-radius: 25px;
}
.create-user-buttom{
  font-family: 'Maven Pro';
}

.notification-table {
  /* margin-right: 50px; */
  /* margin-top: 61px; */
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 0.2fr;
}

.acpt {
  width: 120px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #465cd9;
  border-radius: 100px;
  color: #fff;
  border: none;
  font-size: 14px;
  /* margin: 2rem; */
}

.notification-on {
  width: 442px;

  height: auto;

  border-radius: 24px;
  background: #fff;
}

.push-notif-first {
  background: #ebebeb;

  height: 100px;
  flex-shrink: 0;
  border-radius: 20px;
  box-shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}
.push-notif {
  width: 380px;
  margin-left: 27px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 20px;

  background: #fff;

  box-shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}
textarea:focus {
  outline: none;
  border-color: transparent;
}
.notif1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.push-cont {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.text-push {
  width: 226px;
}

.togl {
  border: black !important;
}

.hrizontl-line {
  height: 8px;
  width: 100%;
  border: 1px solid rgb(126, 126, 126);
  border-color: none;
  outline: none;
}

.imgsssss {
  width: 57px;
  height: 59px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #a5a5a5;
}
.push-modl-header {
  flex-shrink: 0;
  border-radius: 10px;
  background: #f33;
  color: #fff !important;
}
.modal-content {
  border-radius: 10px;
}
/* new additions */
.push-modl-contnt {
  width: 750px;
}
.templates-column {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.templates-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.templates-list {
  flex: 1;
  overflow-y: auto;

  border-radius: 18px 18px 0px 18px;
  
}

.template-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-bottom: 10px;
  border-radius: 18px 18px 0px 18px !important;
  overflow-wrap: break-word;
}

.template-item:hover {
  background-color: #f0f0f0;
}


@media (max-width: 768px) {

  .form-column, .templates-column {
    width: 100%;
    min-width: unset;
  }

  .templates-column {
    height: auto;
  }
  .templates-list{
    height: 33vh;
  }
  .temp{
    height: 300px !important;
  }
}

/* @media (max-width: 480px) {
  .diglo{
    height: 20vh !important;
  }
} */
/* till here */





.cret-notification {
  width: 250px;
  height: 62px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #000;
  background: #f33;
  color: #fff;
  border: none;
}
.crete-new {
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #333;

  border: 2px dashed #818181;
  border-radius: 20px;

  height: 140px;
  text-align: center;
}

.form-check-label {
  position: relative;
}

.form-check-input[type="checkbox"]:checked + .form-check-label:before {
  background-color: gray;
}

.form-check-input[type="checkbox"]:checked + .form-check-label:before:after {
  background-color: black;
}

/* modals s */

.custom-button {
  border-radius: 100px;
  background: #f33;
  width: 300px;
  height: 62px;
  color: #fff !important;
  border: none;
  margin-top: 10px;
}
.custom-button-now {
  border-radius: 100px;
  background: #f33;
  width: 180px;
  height: 52px;
  color: #fff !important;
  border: none;
  margin-top: 10px;
}
.both-send-schdulebtn {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}
.allmodalborder {
  border-radius: 25px;
  border: none;
  /* height: 467px; */
  /* overflow-y: scroll; */
}
.specificUserSeacrh {
  border-radius: 25px;
  border: 1.137px solid #e8e8e8;
  background: #fff;
  width: 100%;
  height: 40px;
  font-size: 17px;
}
.search-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.search-container input {
  padding-left: 33px;
}
.search-container img {
  position: absolute;
  left: 10px;
  /* top: 50%; */
  top: 56%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}
.search-container5 img {
  position: absolute;
  left: 28px;
  top: -3px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}
.timezones {
  width: 269px;
  height: 39.894px;
  border-radius: 7.979px;
  border: 0.798px solid #000;
  background: #fff;
  color: #000;
}
.specificGropSeacrh {
  border-radius: 25px;
  border: 1.137px solid #e8e8e8;
  background: #fff;

  width: 198px;
  height: 40px;
  font-size: 14px;
}
.input-group input {
  width: 20px;
  margin-top: 10px;
  border-radius: 50px;
}
.group-img-file {
  border-radius: 50px;
}
.select-user-forgroup {
  display: flex;
  align-items: center;
  padding: 6px;
}

.cret-new-grp span {
  color: #f33;
  font-size: 12px;
}
.group-select-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: start;
}
.chkDesing:checked {
  background-color: #f33;
  border-color: #f33;
}
.view-details {
  color: #f33;
  cursor: pointer;
}
.Add-new-usr {
  border-radius: 100px;
  background: #f33;
  width: 127px;
  height: 50px;
  color: #fff;
  border: none;
  margin-top: 10px;
  font-size: 10px;
}
.user-select-item {
  display: flex;
  flex-direction: column;

  margin-bottom: 1rem;
}
.user-select-item1 {
  display: flex;

  align-items: center;

  margin-bottom: 1rem;
}

.user-img {
  width: 45px !important;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 13px;
}
.user-img-edit {
  width: 91px !important;
  height: 60px;

  object-fit: cover;
  margin-left: 5px;
}

.user-name {
  font-weight: 600;
  font-size: 14px;
}

.user-email {
  font-size: 0.875rem;
  color: #888;
}
.custom-search-bar-container-3 {
  margin-bottom: 20px;
  display: flex;
}

.custom-search-input-container-3 {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #c9d3df;
  background: #ffff;
  max-width: 410px;
  height: 45px;
  border-radius: 40px;
}
.multi {
  border-radius: 50px;
  margin-left: 5px;
  width: 100px;
  height: 35px !important;
}

.custom-search-icon-3,
.custom-multi-select-icon-3 {
  padding: 10px;
}

/* add more user page */
.add-more-user-container {
  width: 100% !important;
}
.group-icon-name-count {
  display: flex;
  justify-content: flex-start;

  align-items: center;
  width: 90%;
  margin-top: -40px;
}

.user-item-addmorepage {
  display: flex;

  align-items: center;
}

.custom-search-input-3 {
  width: 163px;
  height: 43px;
  font-size: 16px;
  outline: none;
  border: none;
  background: #ffff;
  
  border-radius: 47px;
}
.your-bio5{
  width: 100%;
  height: 120px;
}

.custom-icon-size-3 {
  width: 22px;
  height: auto;
  margin-top: -4px;
}

.custom-dropdown-3 {
  position: relative;
  top: calc(100% + 5px);

  margin-top: -16px;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  width: 222px;

  margin-left: -221px;
}

.dropdown-item-3 {
  padding: 10px;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
}

.dte-time {
  font-size: 17px;
  font-weight: bold;
}
.time-container {
  display: flex;
  justify-content: space-between;
}

.time-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}

.hrs,
.minute {
  width: 60px;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin: 5px 0;
  border: none;
}

.up-arrow,
.down-arrow {
  width: 20px;

  cursor: pointer;
}
.colon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 24px;
  font-weight: 900;
  margin-left: -11px;
}
.your-bio2{
  width: 100%;
    height: 120px;
}
.date-time-zone-contianer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.css-23p0if-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
  background-color: #F33 !important;
}
.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
  color: #fff !important;
    background-color: #F33 !important;
    font-weight: 500 !important;
}
.preview-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.selected-file-info {
  margin-top: 100px;
  margin: auto;
  width: 50%;
  font-size: 10px;
}
.upload-container {
  display: flex;
  flex-direction: column;
}

.filereder-txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.upload-text {
  margin-top: 5px;
  cursor: pointer !important;
}

@media only screen and (max-width: 1200px) {
  .notification-table {
    grid-template-columns: 1fr;
  }
  .notification-on {
    width: 100%;
  }
  .push-notif {
    width: 100%;
    margin-left: 0px;
  }
}

@media (min-width: 992px) {
  .custom-calendar {
    max-width: 80%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .custom-search-input-3 {
    width: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .notification-table {
    grid-template-columns: 1fr;
    margin: auto;
    margin-top: 40px;
  }
  .custom-search-input-container-3 {
    max-width: 350px;
    margin-top: 15px;
  }

  .notification-on {
    width: 100%;
  }
  .push-notif {
    width: 100%;
    margin-left: 0px;
  }
  .push-modl-contnt {
    width: 90% !important;
    margin: auto;
  }
  .upload-container {
    width: 100%;
  }
  .push-modl-header {
    width: 100%;
  }
  .diglo {
    /* height: 0vh; */
    width: 100%;
    margin: auto;
  }
  .cret-notification {
    width: 100%;
  }
  .custom-modal-dialog-sm {
    max-width: 90%;
  }
  .custom-modal-body-sm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .custom-search-input-3 {
    width: 100% !important;
  }
  .custom-button {
    width: 100% !important;
  }
  .time-container {
    justify-content: center;
  }

  .timezones {
    width: 150px;
  }
  .delet-edit-icon-notification img {
    width: 30px;
  }
}

@media screen and (max-width: 480px) {
  .user-name.user-email {
    font-size: 10px;
  }
  .crete-new {
    font-size: 16px;
  }
  .specificGropSeacrh {
    width: 100%;
  }
  .user-img {
    width: 25px !important;
    height: 25px !important;
    border-radius: 50% !important;
  }
  .Add-new-usr {
    width: 100%;
    max-height: 36px;
    /* height: auto; */
    font-size: 8px;
    margin-right: 9px;
    gap: 1px !important;
  }
  .add-crcl-icon{
    width: 13px !important;
  }
  .specificUserSeacrh {
    width: 194px;
  }
  .dte-time {
    font-size: 13px !important;
  }
  .custom-search-input-container-3 {
    width: 100% !important;
  }
  .multi {
    width: 71px;
  }
  .custom-button-now {
    width: 120px;
    height: 34px;
  }
  .both-send-schdulebtn {
    display: flex;
    flex-direction: column;
  }
  .view-details {
    font-size: 9px;
  }
  .user-name {
    font-size: 9px;
  }
  .user-email {
    font-size: 9px;
  }
  .timezones {
    width: 100px !important;
  }
  .date-time-zone-contianer {
  
    justify-content: center !important;
    flex-wrap: wrap !important;
  }
  .diglo{
    height: 20vh !important;
  }
}
