.crd2{
    display: flex;
    justify-content: space-evenly;
    padding: 8px 15px;
    border-radius: 10px;
    /* background: #D9D9D9; */
    background-color:#FFF;;
    box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.08);
    font-weight: bold;
   
    height: 123px;
    flex-shrink: 0;
    color: #000;
    /* width: 314px; */
    text-align: center;
    font-family: "Maven Pro";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    cursor: pointer;
  }
  
  .crd-icons {
    height: 150px;
    width: 165px;
  }
  
  /* Responsive adjustments */
  @media (max-width:992px) {
    .commoncard {
      max-width: 100%; /* Set maximum width to full width for smaller screens */
      width: auto; /* Reset width to auto */
    }
  }
  