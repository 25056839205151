.faq-container{
    width: 100%;
   /* max-width: 1544px; */
    /* max-height: 180px; */
    background-color: #fff;
    box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.08);
   border-radius: 10px;
    margin-top:-20px;
    /* overflow:auto; */
}
.faq-bttn-wraper{
    margin-top: 88px;
    max-width: 100%;
}
.faq-edit-dlt{
    display: flex;
    justify-content: end;
    /* z-index: -1; */
    position: relative;
}
.faq-edit-dlt>button{
background-color: #FFFF;
box-shadow:0px -1px 0.1px 0px rgba(0, 0, 0, 0.08);
color:#465CD9;
width: calc((283px / 2));
height: 76px;
/* border-radius: 10px 0px 0px 10px; */
outline: none;
border: none;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 25px;
}
.faq-delet-btn>button{
    border-radius: 0x 10px 0px 10px;
}
.faq-edit-btn>button{
   
    border-radius: 10x 0 0px 10px;
}
.faq-container{
    padding: 33px
}
.faq-first-question{
    font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.faq-content{
    overflow: hidden; 
    font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 25px
}
.modl-design{
/* width: fit-content; */
width: 183%;
}
.modl-header-design{
    width: 915px;
    height: 109px;
    color: white;
    font-weight: bold;
    /* background-color: #EFEFEF; */
    background-color: var(--crete-user-btn);
    border-radius: 10px;
}
.foter-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
    
}
.modl-design{
    margin-left: -40%;
}
.modal-backdrop.show {
    /* filter: blur(10px); */
    /* filter: blur(5px); */
    /* background:transparent; */
    /* backdrop-filter:blur(2px); */
    opacity: 80%;
    background-color: rgba(0, 0, 0, 0.7); 
}


@media screen and (max-width:992px) {
    .modl-design {
        margin-left: -17%;
    }
    
    .modl-design {
        /* width: fit-content; */
        width: 140%;
    }
    .modl-header-design {
        width: 696px;
        height: 109px;
        color: white;
        font-weight: bold;
        /* background-color: #EFEFEF; */
        background-color: var(--crete-user-btn);
        border-radius: 10px;
    }
}

@media (max-width: 768px) {
    .modl-width-faq{
        width: 100%;
        margin: auto;
    }
  
    .modal-header {
      /* padding: 1rem; */
      width:100%;
    }
  
    .modal-body {
      padding: 1rem;
    }
    .txt-editor{
        height: auto;

    }
    .foter-btn{
        width: 100%;
        margin-top: 133px ;
    }
    .faq-bttn-wraper{
        margin-right: 22px !important;
    }
    /* .modl-design{
        margin-left: 0%
    } */
  }

  @media screen and(max-width:480px) {
    .modl-width-faq{
        width: 100% !important;
        margin: auto;
        
      }
    
  }
  