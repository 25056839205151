
.responsive-card {
  width: 285px;
  min-height: 196px;
  height: auto;
  margin-bottom: 20px; 
  flex: 0 0 calc(33.33% - 20px);
  box-sizing: border-box;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  /* text-align: center;  */
  word-wrap: break-word; 
  word-break: break-word; 
  text-wrap: pretty;
}


.custom-card-content img {
    max-width: 100%;
    max-height: 100%;
}



.custom-card-section {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between;
  width: 100%;
  max-width: 855px;
 
  margin-top: 25px;
}



.custom-card-content {
  display: flex;
  align-items: baseline;
 
  padding: 20px;
  flex-direction: column;
}

.custom-card-text {
  
  margin-top: 20px;

}
.custom-card-text > p:first-child {

  color: #f33;
  font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.custom-card-text>p:nth-child(2){
  font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;

}
 
.barom-app-text{
  font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
  margin-top: 48px;
} 



@media screen and (max-width: 768px) {
  .responsive-card {
      flex: 0 0 calc(50% - 20px); 
      margin-top: 30px;
  }
}


@media screen and (max-width: 480px) {
  .responsive-card {
      flex: 0 0 calc(100% - 20px); 
  }
}




