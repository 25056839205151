.create-user-buttom{
width: 220px;
height: 75px;
/* width: 245px;
    height: 63px; */
flex-shrink: 0;
border-radius: 100px;
background-color:var(--crete-user-btn);
border: none;
outline-style: none;
color: white;
cursor: pointer;
font-weight: bold;
font-size: 14px;
}
.create-user-buttom:hover{
    color: white;
}


@media screen and (max-width:1200px) {
    .create-user-buttom{
        width: 180px;
        height: 65px;
        font-size: 12px;
    }
    
}


@media screen and (max-width:767px){
    .create-user-buttom{
        width: 150px;
        height: 55px;
        font-size: 10px;
    }
    .plus-icon{
        width: 15px;
    }
}
