.global-search-suggestions {
    position: absolute;
    top: 60px;
    left: 73px;
    width:100%;
    max-width: 510px;
    background: #fff;
    border: 1px solid #dfe1e5;
    border-radius: 0 0 24px 24px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    z-index: 1000;
    max-height: 300px;
    overflow-y: auto;
  }
  
  .suggestion-item {
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 1px solid #dfe1e5;
  }
  
  .suggestion-item:last-child {
    border-bottom: none;
  }
  
  .suggestion-item:hover {
    background-color: #f1f3f4;
  }
  
  
  @media screen and (max-width:1200px) {
    .global-search-suggestions{
      width:296px;
    }
  }
  @media screen and (max-width:992px) {
    .global-search-suggestions{
      max-width: 248px;
      width: 30%;
      left: auto;
      margin-left: 20px;
    }
  }
  @media screen and (max-width:767px) {
    .global-search-suggestions{
      display: none;
    }
  }

