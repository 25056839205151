.Account-managment-desgin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.Account-route {
  color: #222;
  font-family: "Maven Pro";
  font-size: 22px;

  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hed-desgin-modl {
  background: #f33;
  color: white;
  font-weight: bold;
}
.admin-mng-buttn {
  background-color: white;
}
.create-usr-butn {
  width: 400px;
  height: 62px;
  flex-shrink: 0;
  border-radius: 10px;
  font-family: "Maven Pro", "sans-serif";
  color: white !important;

  background: #f33;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}

.user-design-crd {
  width: 422px;
  height: 119px;
  flex-shrink: 0;

  border-radius: 20px;
  background: #fff;
  border-radius: 10px;
  margin-right: 18px;
  cursor: pointer;
  box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.08);
}
.user-info {
  display: flex;
  flex-direction: column;
}

.card-text {
  margin-bottom: 1rem;
}

.name-txt,
.eml-txt,
.admn-txt {
  margin: 0; /* Reset default margin */
}

.dynmic-crd-design {
  margin: 98px 188px 0px 0px;
}
.name-txt {
  color: #000;

  font-family: "Maven Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.eml-txt {
  color: #8c8c8c;

  font-family: "Maven Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.admn-txt {
  /* color: #343434; */
  color: #f33;
  /* text-align: center; */
  font-family: "Maven Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.desing-new-btn {
  cursor: pointer;
}
.new-admin-txt{
  font-family: "Maven Pro", "sans-serif";
}
.btn:focus,
.btn:active,
.btn:focus-visible {
  outline: none;
  box-shadow: none;
}
.admn-logos {
  width: 80px;
  height: 80px;
}
.view-delet-admin{
  border-radius: 25px;
  background-color: #FFFFFF;
 
}
.view-user-account{
color: #f33;
}
@media (max-width: 767px) {
  .admn-logos {
    width: 60px;
    height: 60px;
  }
  .new-admin-txt{
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .user-design-crd {
    width: 80% !important;
  }
  .name-txt,
  .eml-txt,
  .admn-txt {
    font-size: 13px;
  }
  .admin-drop-butn {
    margin-left: -27px !important;
  }
  .modl-deisgn {
    margin-top: 15px;
  }
  .create-usr-butn {
    width: 90%;
  }
  .new-admin-txt{
    font-size: 16px;
  }
}
@media screen and (min-width: 480px) {
  .admin-drop-butn {
    margin-left: 60px;
  }
}

@media screen and (max-width:1200px) {
.adminmanagmnet-cardss{
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-admin-txt{
  font-size: 22px;
}
}

