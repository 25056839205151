.butn {
  width: 296px;
  height: 58px;
  background-color: #f33;
  border-radius: 10px;
  color: white;
  font-family: "Maven Pro";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
}
.butn:hover {
  color: white;
}
.Suspicious-Acccount {
  width: 296px;
  height: 58px;
  border-radius: 10px;
  border: 1px solid #f33;
  background-color: #ffff;
  font-family: "Maven Pro";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #f33;
}

@media (max-width: 576px) {
  .butn {
    width: 100%;
  }
  .Suspicious-Acccount {
    width: 100%;
  }
}
