.suscription-container{
    display: flex;
    justify-content: space-between; 
    margin-top: 82px;
    flex-wrap: wrap;
  }
  
  .boxes{
    
    width: 283px;
    height: 135px;
    flex-shrink: 0;
    /* background-color: #ccc; */
    border: 1px solid #000;
    /* border: none; */
    border-radius: 10px;
    padding: 22px;
    text-align: center;
  }
  .suscription{
    display: flex;
    gap: 28px;
    flex-wrap: wrap;
  }
  .suscription-serch-container{
    margin-top: 55px;
  }
  .suscription-report-tbl{
    margin-top: 51px;
  }
  .boxes-text1{
    font-family: "Maven Pro";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .boxes-text2{
    font-family: "Maven Pro";
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .suscription-more-btn{
    width: 347px;
height: 74px;
border-radius: 10px;
background-color: #D9D9D9;
border: none;
  }

  @media screen and (max-width:992px) {
    .butn-waper-suscription{
        margin-top: 20px;
    }
    .suscription-more-btn{
        width: 280px;
    }
  }