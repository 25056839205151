.card-style1{
  width: 217px;
    /* width: 100%; */
   min-height: 126px;
   height: auto;
    /* border: 1px solid #ccc; */
    border: none;
    /* border-radius: 8px; */
    border-radius: 10px;
background-color: var(--grey2);
box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.08);
padding: 10px;

  }
  .card-style-text1{
    color: #000;
    margin-left: 5px;
/* text-align: center; */
padding: 5px;
font-family: "Maven Pro";
font-size: 31px;
font-style: normal;
font-weight: 500;
line-height: normal;

overflow: hidden;
text-align: center; 
word-wrap: break-word; 
word-break: break-word; 
text-wrap: pretty;
  }
  .card-style-text2{
    color: #000;
    /* text-align: center; */
    font-family: "Maven Pro";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
  }
  .card-style-3{
    color: #000;
    /* text-align: center; */
    font-family: "Maven Pro";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
 .crd5{
 cursor: 'pointer';
 text-decoration:'none'
 }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .card-style1{
      width: 100%; 
      height: auto; 
    }
}