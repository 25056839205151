.add-faq{
width: 400px;
height: 62px;
border-radius: 10px;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: normal;
/* border: none; */
/* background-color: white; */
background-color: var(--crete-user-btn);
display: flex;
justify-content: center;
align-items: center;
color: white;
border: none;
}

@media (max-width: 768px) {
.add-faq{
    /* width: 286px; */
    width: 217px;
}
}
@media screen and (max-width:480px) {
    .add-faq{
        margin-top: -80px;
    }
    
}