.referl-table{
   margin-top: 55px; 
   width: 880px;
   margin-right: 694px;
}
.algn-top-bar{
    color: #222;

font-family: "Maven Pro";
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
 
@media screen and (max-width:576px){
.referl-table{
    width: 100%;
}
    
}