.wraperr {
  display: flex;

  flex-wrap: wrap;
  gap: 17px;
  justify-items: stretch;
  justify-content: center;
  margin: 54px 0 0 -36px;
}

.edit-yourname {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.name-img-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bar-det-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.links-text-design {
  display: flex;
  justify-content: space-between;
}
.linked-in {
  display: flex;
  justify-content: start;
}

.website-text-designs > label {
  font-family: "Maven Pro";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.website-link {
  width: 470px;
}
.documnets {
  margin-top: 45px;
}

.more-option-btn {
  width: 250px;
  height: 62px;

  border-color: #d9d9d9;
  background-color: #fff;
  border-radius: 10px;
  margin: 0px 89px 0 0;
}

.suspecious {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.documnets {
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.box-container {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
    row-gap: 15px;
}

.reumeicon {
  border: 1px solid red;
  width: 96%;
  height: 60%;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border-radius: 10px 10px 0px 0px;
  border: 4px solid #f4fbff;
  background: #fff;
}
.box {
  border-radius: 10px;
  opacity: 0.7;
  background: #c6e9ff;
  width: 100%;
  max-width: 174px;
  height: 113px;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  margin-right: 10px;

  display: flex;
  justify-content: center;
  flex-direction: column;
}
.resumecontainer {
  background-color: white;
}
/* .resumenmae {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */
.your-bio-bartender{
 
    width: 100%;
    height: 120px;

}


.box p {
  font-size: 12px;
  margin: 0px;
}
.drp-dwn-border {
  width: 251px;
  padding: 5px;
  border-radius: 10px;
}
.dropdown-menu.drp-dwn-border li {
  margin-top: 10px;
}
.height-design-line {
  height: 5px;
  background-color: #d9d9d9;
  border: none;
  margin-bottom: 5px;
}
/* Media Query for smaller screens */

@media (max-width: 767px) {
  .website-link {
    width: 100%;
  }
  .name-img-count {
    text-align: center;
  }
  .box-container {
    flex-wrap: wrap;
    column-gap: 20px;
  }
  .box {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .wraperr {
    gap: 20px;
    width: 91vw;
        display: grid;
        /* margin: auto; */
        grid-template-columns: 1fr 1fr;
        margin-left: 13px;
  }
}
@media (max-width: 1599px) {
  .website-link {
    width: 98%;
  }
}

@media (max-width: 480px) {
  .wraperr{
    margin-left: -5px;
  }
  .suspecious{
    margin-bottom: 20px;
    margin-top: 20px;
  }
  /* .wraperr {
    justify-content: space-around;
  } */
}
