.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin: 20px;
  }
  
  .page-buttons {
    display: flex;
    gap: 3px;
  }
  
  .page-button,
  .prev-btn,
  .next-btn {
    padding: 5px 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: white;
    font-size: 14px;
    border-radius: 3px;
  }
  
  .page-button.active {
    background-color: #f33;
    color: white;
  }
  
  /* .page-button:hover,
  .prev-btn:hover,
  .next-btn:hover {
    background-color: #eee;
  } */
  