.inputField {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 100px;
  border: 0.91px solid #e9ecef;
}
.verifyres {
  /* width: 499px !important; */
  width: 100% !important;
  height: 50px !important;
  border-radius: 10px;
  border: none; /* Remove border property */
  background: #fff;
  text-align: center;
  color: #343434;
  font-family: "Maven Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* margin-bottom: 40px; */

  border-radius: 124px;
  font-weight: 600;
  background: #ffcc42;
  margin-top: 35px !important;
}
.resetpss {
  width: 499px;
  width: 100% !important;
  height: 50px !important;
  border-radius: 10px;
  border: none; /* Remove border property */
  background: #fff;
  text-align: center;
  color: #343434;
  font-family: "Maven Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* margin-bottom: 40px; */

  border-radius: 124px;
  font-weight: 600;
  background: #ffcc42;
}
.header {
  font-size: 24px;
  font-family: "Maven Pro", sans-serif;
}
.otp-chnggg {
  border: 2px solid rgb(143, 143, 143);
  width: 60px !important;
  height: 60px !important;
  border-radius: 10px;
}
.allfonts {
  font-family: "Maven Pro";
}

.custom-container-verify {
  width: 520px;
  height: 450px;
  max-width: 100%;
  max-height: auto;

  padding: 20px;
  border-radius: 50px !important;
}

.custom-logo-verify {
  position: absolute;
  margin-top: -111px;

  width: 150px;
}

.custom-otp-input-verify {
  gap: 10px; /* Add the gap property to replace 'gap-5' */
}

.otp-input-verify {
  height: 55px;
  width: 55px;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #000;
  margin: 0 5px;
  outline: none;
  font-size: 14px;
}

/* cretepsas */

.custom-container-create {
  font-family: "Maven pro", sans-serif;
}

.custom-box-create {
  border-radius: 50px !important;
  /* width: 723px; */
  width: 520px;
  height: 471px;
  /* height: 471px; */
  max-width: 100%;
  max-height: auto;
  padding-top: 3rem !important; /* Adjust the top padding as per your needs */
}

.custom-logo-create {
  position: absolute;
  /* margin-top: -90px; */
  margin-top: -124px;
  width: 150px;
}

.custom-section-create p {
  margin-bottom: 0;
}

.custom-input-container-create .input-group {
  border: 1.24px solid #dcdcdc;
  border-radius: 50px;
  padding: 5px;
}

.custom-input-create {
  border: none;
  outline: none;
  width: 100%;
}

.custom-icon-create {
  width: 25px;
}

.custom-button-create {
  border-radius: 50px;
}

@media screen and (max-width: 480px) {
  .custom-section-create {
    font-size: 16px;
  }
  .create-new-passwords {
    font-size: 16px;
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .custom-container-verify {
    padding: 2px !important;
    width: 500px !important;
  }
  .custom-logo-verify {
    margin-top: -55px;
  }
  .code-verify-text {
    margin-top: 50px;
  }
}

@media (max-width: 576px) {
  .forgetPassword {
    font-size: 14px;
  }
  .custom-logo {
    margin-top: -50px !important;
  }
  .custom-container-verify {
    /* width: 320px !important; */
    width: 100% !important;
    height: 469px;
  }
  .code-verify-text {
    font-size: 34px;
  }
  .sent-mails {
    font-size: 17px;
  }
  .custom-otp-input-verify {
    margin-left: 22px;
  }
}

@media screen and (max-width: 1200px) {
  .custom-container-verify {
    padding: 0px !important;
    /* max-width: 500px !important; */
  }

  .custom-logo-verify {
    margin-top: -64px;
  }
  .code-verify-text {
    margin-top: 50px;
  }

  .verifyres {
    width: 90% !important;
  }
  .custom-box-create {
    width: 100%;

    height: auto;
    padding: 10px !important;
  }
  .confrmpssword {
    width: 100% !important;
    padding-top: 40px;
  }
  .custom-logo-create {
    /* margin-top: -107px; */
    margin-top: -81px !important;
  }
  .create-new-passwords {
    margin-top: 38px !important;
    /* font-size: 28px !important; */
    font-size: 25px !important;
  }
}
