
  .table-responsive2 thead tr {
    border-bottom: 1px solid rgb(219, 219, 219);
    margin-top: 100rem !important;
  }
  
  .table-heading2{
    color: #222;
  font-family: "Maven Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
    text-align: left !important;
  }
 .td-design2 td {
    text-align: left !important;
  }
  .td-design2{
    color: #000;
    vertical-align: middle !important;
  font-family: "Maven Pro";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  }

  .view-report{
    background: none;
    border: none;
    color: #5E7FB1;;
  }
  .user{
    display: flex;
    align-items: center;
    /* border: 1px solid #ccc; */
    padding-top: 5px;
    /* border-radius: 5px; */
  }

  .user img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-right: 20px;
  }

  .user-details {
    display: flex;
    flex-direction: column;
  }

  .user-details .name {
    font-size: 1.2em;
    font-weight: bold;
  }

  .user-details .email {
    font-size: 0.9em;
    color: #666;
  }
  
  
  
  /* @media  (max-width: 500px)  {
  .td-design2{
    font-size: 8px;
  }
  } */
  
  
  @media (min-width: 576px) and (max-width: 1200px)  {
    .td-design2 {
        font-size: 16px;
    }
  }