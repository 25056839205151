.avatar-upload {
  position: relative;
  max-width: 149px;
  margin: auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 108px;
  left: 102px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 37px;
  height: 39px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid #232222;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input + label:after {
 
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 150px;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 150px;
  height: 148px;
  position: relative;
  border-radius: 100%;
  border: 1px solid black;
}

.avatar-upload .avatar-preview > div {
  /* margin-top: 150px; */
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
/* .half-curve-design {
  position: relative;
  border:8px solid #F33;
  width: 86px;
  top: -155px;
  left: 69px;
  height: 162px;
  border-top: e;
  border-right: none;
  border-bottom-left-radius: 101px;
  border-top-left-radius: 102px;
  transform: rotateY(180deg) rotateX(180deg);
} */

/* @media (max-width: 768px) {
 

} */
/* @media (min-width:'768px') {
  .avatar-upload .avatar-edit{
    top: 150px; 
    right: 20px; 
  }
} */



 
@media (max-width: 768px) {
    .elips-text,
    .elips-email {
      margin-top: 0.5rem;
    }
    .avatar-upload .avatar-edit {
      top: 104px; 
      right: 20px; 
  }
    .avatar-upload {
      max-width: 100% ;
    }
    .avatar-upload .avatar-preview{
      margin: auto;
    }
    .profile-ext{
      width: 100% !important;
    }
    .elips-text{
      text-align: center;
      margin-top: 20px;
      color: #222;
  
  text-align: center;
  font-family: "Maven Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  .elips-email{
      text-align: center;
      color: #5B5B5B;
      text-align: center;
      font-family: "Maven Pro";
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  
  }.prfile-ext{
    width: 150px;
  }
    /* .half-curve-design{
      left: 255px;
    } */
  }

  .gopencil{
   width:23px;
   height:35px;
   position:relative;
   left:3px;
   color: #f33;
  }