.view-suscription-wrapper{
    margin-top: 54px;
}

.viewsusc-edit{
    display: flex;
    justify-content: center;
    margin-top: 44px;
    gap: 20px;
    flex-wrap: wrap;
    /* margin-left: -759px; */
}


.suscription-pln{
    margin-top: 64px;
}
.suscription-pln p:first-child{
    color: #7E7E7E;
font-family: "Maven Pro";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.suscription-pln p:nth-child(2){
    color: #343434;

    font-family: "Maven Pro";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.toggle-switch{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 20px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-top: 64px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  /* input:checked + .slider {
    background-color: black;
  } */
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-color: black;
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }


  /* ****************************************** */
 /* .add-suscrpt-container{
  width: 1920px;
height: 1080px;
margin-top: 78px;
border: 1px solid red;
  } */
 
  