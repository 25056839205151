
.gig-detail-2{
margin-top: 40px;
background: #FFF;
box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.08);
border-radius: 10px;
padding: 25px;
}
.same-design-all p:first-child{
    color: #4B4B4B;
font-family: "Maven Pro";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.same-design-all p:last-child{
    color: #919191;

font-family: "Maven Pro";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
opacity: 0.5;
}

@media screen and (max-width:576px) {
    .gig-detail-2{
        margin-bottom: 39px !important;
    }
    
}