.header{
    background-color: transparent;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    position: relative;
  }
  .changeColor{
    color: white;
  }
  .changeColor:hover{
    color: #FFCC42;
  }
  .barrom{
    display: none;
    z-index: 2;
    position: absolute;
    top: 20px;
    left: 70px;
    height: 80px;
  }
  .backgroundImg{
    z-index: 0;
    height: 18vh;
    width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .lowerPart{
    position: relative;
  }
  .bImg{
    height: 100%;
    width: 100%;
  }
  .lowerPart h3{
    font-weight: 400;
    color: #fff;
    position: absolute;
    left: 80px;
    bottom: 80px;
    font-size: 20px;
    line-height: 20px;
    font-family: Righteous;
    font-size: 30px;
  }
  .lowerPart span{
    color: #FFCC42;
  }
  .middlePart{
    margin: 20px 0;
  }
  .middlePart h4{
    font-size: 23px;
    font-weight: 400;
    letter-spacing: 0.8px;
    line-height: 20px;
  }
  .contentSection{
    margin: 50px auto;
    min-height: 60vh;
    width: 90%;
    max-width: 1200px;
  
  }
  .contentSection p{
    margin: 16px 0;
    color: #555;
    font-weight: 400;
    font-family: "poppins";
    letter-spacing: 0.42px;
  }
  .contentSection h5{
    font-size: 20px;
    margin-top: 20px;
    font-weight: 400;
    margin-bottom: 50px;
    line-height: 35px;
    letter-spacing: 0.5px;
  }
  .contentSection span{
    color: #FF3333;
  }
  @media screen and (min-width:850px) {
    .barrom{
      display: block;
    }
    .backgroundImg{
      height: 25vh;
    }
  }