.question {
  width: 99%;
  height: 62px;
  border-radius: 10px;
  background-color: #efefef;
  border: none;
  text-indent: 10px;
}

.answer {
  width: 99%;
  border-radius: 10px;
  background-color: #efefef;
  border: none;
  text-indent: 10px;
}
.txt-editor {
  margin-top: 20px;
}

.req-ss {
  height: auto;
}
.ql-container.ql-snow {
  border: none !important;
}

@media screen and (max-width: 768px) {
  .req-ss {
    width: 98% !important;
  }
}
:host {
  .ql-container {
    background-color: rgb(239, 239, 239);
  }
}
