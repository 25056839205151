.gig-detail-1{
    margin-top: 40px;
    background: #FFF;
box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.08);
border-radius: 10px;
padding: 25px;
}
.common-detail p:first-child{
    color: #4B4B4B;
    /* font-family: "Maven Pro"; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
}
.skills-req p:not(:first-child) {
    color: #919191;
  }
.common-detail p:last-child{
    color: #919191;
/* font-family: "Maven Pro"; */
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
opacity: 0.5;
}
.duties-respons ul li{
    color: #919191;
/* font-family: "Maven Pro"; */
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
opacity: 0.5;
}

.benefits ul li{
    color: #919191;
    /* font-family: "Maven Pro"; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
}

@media screen and (max-width:767px) {
    .gig-detail-1{
        margin-bottom: 39px !important;
    }
    
}