.gigs-app-text{
    color: #222;

font-family: "Maven Pro";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 45px;
}
.gigs-crd{
    margin-top:55px
}
.gigs-tbl{
    margin-top: 52px;
}
.address-cell{
    max-width: 100px; 
    word-wrap: break-word;
    /* padding: 50px; */
}