.estb-candidate-hired{
    margin-top: 90px;
}
.Candidate-hired{
    margin-top: 45px;
    font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.candidate-tbl{
    margin-top: 50px;
}
.view-profile-link{
    color: #7A7A7A;

    font-family: "Maven Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    /* text-decoration: none; */
}
.view-profile-link:hover{
    color:  #7A7A7A;
}

.cnditn-nme{
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}