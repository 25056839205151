.header-design1 {
  height: 150px;
  background-color: #f6f6f6;
  margin-top: -46px;
  margin-left: -46px;
  margin-right: -8px;

  box-shadow: inset 0px -16px 16px -16px rgba(0, 0, 0, 0.1);
}

.welcome-text {
  display: flex;
  align-items: center;
  margin-top: 0px;
  color: #222;
  font-family: "Maven Pro";
  font-size: 28px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.welcome-kate {
  color: #222;
  font-family: "Maven Pro";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.elips-wrap-contanr {
  display: flex;
  margin-left: 40px;
}
.elips-icon-2 {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.hr-line {
  height: 1px;
  color: #9e9999;
  width: 98%;
  margin-left: 52px;
}

@media (max-width: 992px) {
  .serch-bar {
    width: 250px !important;
  }
}

@media screen and (max-width: 576px) {
  .hr-line {
    display: none;
  }
  .header-design {
    background-color: var(--very-lightgrey);
    padding: 10px 0;
    height: 110px;
  }
  .header-design1 {
    display: none;
  }
}
