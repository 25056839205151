/* Base styles */

.estb-profile {
  margin-top: 48px;
  max-width: 1673px !important;
  /* margin-left: -11px; */
}

@media screen and (max-width: 1200px) {
  .estb-profile {
    margin-left: 12px;
  }
}
/* .estab-button {
} */

.estb-p {
  width: 25%;
}

.simple-line {
  height: 5px;
  width: 100%;
  background-color: #d9d9d9;
}

.input-width {
  width: 100%; /* Adjust as needed */
}
.estb-det-img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  /* margin-left: -80px; */
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  /* margin-top: 56px; */
}
.estb1-btn {
  width: 245px !important;
  min-height: 63px;
  height: auto;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #f33;
  color: #f33;
  flex-shrink: 0; 
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center; 
  word-wrap: break-word; 
  word-break: break-word; 
  text-wrap: pretty;
}

.img-container {
  border-radius: 20px;
}
.estb1-btn.active {
  background-color: #f33;
  color: white;
}



.estb-all-btn {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  /* flex-wrap: wrap; */
  max-height: 200px;
  overflow-x: scroll;
  overflow-x: auto; 
 
  white-space: nowrap; 
  padding-left: 10px;
  scroll-behavior: smooth; 
}


.estb-crds {
  position: relative;
  top: -78px;
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 33px;
  margin-left: 28px;
  row-gap: 10px;
}

.estb-warpper {
  width: 80%;
}

.estb-txt1 p:first-child {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.estb-txt1 p:nth-child(2) {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.estb-txt p {
  line-height: 5px; /* Adjust this value as needed */
}

.estb-txt p:first-child {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.estb-txt p:nth-child(2) {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
}

.estb-profile-btn {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

.containersss {
  margin: 0px 61px 0 4px;
}
.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-text {
  margin-top: 10px; /* Adjust margin as needed */
}

.referal-button {
  /* width: 172px; */
  width: 150px;
  min-height: 44px;
  height: auto;
  cursor: pointer;
  display: inline-flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 19px;
  border-radius: 100px;
  background-color: #f33;
  color: #fff;
  border: none;
  font-size: 13px;
  
}

.arow-referl {
  display: flex;
  justify-content: start;
}
.estb-profile-name {
  width: 101%;
  /* margin-left: -80px; */
  font-weight: 600;
  font-size: 17px;
  word-wrap: break-word;
  /* text-wrap: wrap; */
}

.more-option-btn-estb {
  /* width: 100%; */
  height: 62px;
  border-color: #d9d9d9;
  background-color: #fff;
  border-radius: 10px;
  /* margin: 15px 131px 0 0;*/
}
.btn-option-estb {
  width: 250px;
}
.btn-option-estb ul {
  width: 100%;
}
/* Media Queries for responsiveness */

@media screen and (max-width: 1200px) {
  .estb-more-option-btn {
    display: flex !important;
    justify-content: right !important;
    align-items: center !important;
  }
}
@media screen and (max-width: 767px) {
  /* Adjustments for smaller screens */
  .estb-all-btn {
    gap: 20px;
    /* justify-content: center; */
  }

  .estb-profile-btn {
    gap: 20px;
  }

  .containersss {
    margin: 0px;
  }

  .estb-crds {
    width: 100%;
  }
  .input-width {
    width: calc(100% - 32px); /* Adjust padding and width for small screens */
    padding: 0 16px;
  }
  .estb-det-img {
    margin: 0px;
  }
  .arow-referl {
    justify-content: center;
  }
  .estb-profile-name {
    /* margin: auto; */
    margin-top: 10px;
    text-wrap: wrap;
  }
}

@media screen and (max-width: 567px) {
  .more-option-btn {
    margin: 0px !important;
  }
  .estblishmnet-imges-design{
    margin-right: 12px;
  }
  .estb-warpper{
    margin-top: -40px;
  }
}

@media screen and (max-width: 480px) {
  .estb-crds{
    margin-left: 6px;
    margin-top: 95px;
    max-width: 306px;
  
  }
  .estb-more-option-btn{
    margin-left: 15px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1200px) {
  .estb1-btn {
    /* width: 339.839px; */
    width: 200px !important;
    height: 60px;
  }
}
