.fixed-size-div {
  width: 300px;
  min-height: 126px;
  flex-shrink: 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.08);
  font-family: "Maven Pro", "sans-serif";
  padding: 25px;
  margin-top: 45px;
  overflow-wrap: break-word;
}
.fixed-size-text {
  font-size: 31px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #f33;
}
.status-paid {
  color: #00992b;
}

/* .status-pending {
   
  } */
.status-processed {
  color: #e6a800;
}
.status-initialized {
  color: #f33;
}
.fixed-sze-text1 {
  color: #000;

  font-family: "Maven Pro";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-table td {
  padding-top: 30px;
  /* padding-left: 20px; */
}

.custom-table td {
  text-align: center !important;
  margin-top: 10px;
}
.custom-table {
  width: 100%;
}
.table-container {
  overflow-x: auto;
  max-width: 100%;
  margin: 36px 40px 0px 0px;
}

.table-container {
  box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

@media screen and (max-width: 480px) {
  .fixed-size-div {
    width: 200px;
  }
}
