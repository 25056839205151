

.suscrpt-plan{
    width: 1230px;
height: 50px;
flex-shrink: 0;
border-radius: 10px;
border: 1px solid #000;
padding: 20px;
background: #FFF;
  }
  .add-suscrpt-container{
    margin-top:82px;
  }
  .inpt-containr1{
    margin-top: 20px;
  }
 
  .same-margin{
    margin-top:10
  }
  .same-margin label{
    margin-top:10 !important;
  }
  .inpt-containr1 textarea{
    padding: 20px;
  }
  @media screen and (max-width:1600px) {
    .add-suscrpt-container .plan input[type='text'],
    .add-suscrpt-container .price input[type='text'],
    .add-suscrpt-container .discnt-price input[type='text'],
    .add-suscrpt-container .features textarea {
      width: 95%; 
    }
  } 