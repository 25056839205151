.acnt{
    margin: 292px 346px 0 0;
}
.wrapper4{
  margin-top: 50px;
}
.home-route{
    color: #222;
font-family: "Maven Pro";
font-size: 22px;
font-style: normal;
/* font-weight: 500; */
line-height: normal;
}
/* .acnt-mangemnet{ */
    /* margin: 100px 346px 0 0px !important; */
      /* margin: 100px 346px 0 49px; */
/* } */
.update-accunt{
   margin: 100px 346px 0 0px !important; 
}

/* .input-container1{
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #C9D3DF;
   
    background: #EFEFEF;
    outline: none;
} */
.comn-inpt-property{
    width:85% !important;
    max-width:441px;
    height: 62px;
    outline: none;
    border: none;
    background: #FFFFFF;
    border-radius: 10px
}

.ElipsIcon3{
    margin-top: 55px;
    height: 170px !important;
}

.spcing-btween-grd2{
  /* column-gap: 65px; */
  column-gap: 51px;
  margin-right: 13px;
}
.spcing-btween-grd1{
  /* column-gap: 65px; */
  column-gap: 51px;
  margin-right: 13px;
}
/* .your-bio{
    width:914px;
    height:120px ;
} */
.same-icon-size{
    /* height: 30px ;
    width: 30px ; */
    width: 30px;
    height: auto;
    flex-shrink: 0; 
}
.text-area-icon{
    margin-top: -95px;
}

.pass-margin{
    margin-top: 5px;
}
.delet-details{
    display: flex;
    color: #222;
    font-family: "Maven Pro";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 33px;
    line-height: normal;
    justify-content: end;
}
.butn-adjust{
    display: flex;
    justify-content: center;
    margin-top: 49px;
}

.line-color{
    margin-top: 28px;
    color: #A0A0A0;
   
}
.delet-account{
    margin-bottom: 63px;
}
@media (max-width: 320px) {
    .col-md-12.mb-3 .input-container1 textarea {
      width: 100%;
    }
   
  }

@media (max-width: 768px) {
    .col-md-12.mb-3 .input-container1 {
      width: 100%; 
    }
  
    .col-md-12.mb-3 textarea {
      width: calc(100% - 40px); 
    }
    .update-accunt{
      margin: 0px !important; 
   }
    /* .acnt-mangemnet{ */
      /* margin: 100px 346px 0 0px; */
        /* margin: 0px;
        width: auto; */
  /* } */
  .butn-adjust {
    text-align: center; 
  }

  .butn-adjust button {
   
    max-width: 217px; 
  }
  }

@media (max-width: 992px) {
    /* .col-md-2.text-center.mb-3 {
      margin-top: -80px;
    } */
    /* .elips-text,
    .elips-email {
      margin-top: 0.5rem;
    } */
    /* .comn-inpt-property{
      width: 188px;
          } */
  }
/* @media (max-width: 768px) {
   
   
  } */

  