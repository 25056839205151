.wraperr-candidate{
    display: flex;
    /* align-content: space-between; */
    /* flex-direction: column; */
    justify-content: center;
    /* align-items: center; */
    gap: 22px;
    margin-bottom: 30px;
    margin-top: -10px;
    flex-wrap: wrap;
    cursor: default !important;

}
.wraperr-candidate> card5{
    cursor: default;
}
@media screen and (max-width:767px) {
    .wraperr-candidate{
        margin-top: 30px;
    }
    
}