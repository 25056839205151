#sidebar {
    background-color: var(--grey2);
    background-image: url('../Assests/Dashboard 1.png');
}

.sidebar-list {
    margin-top: 204px;
}

.sidebar-list-dash,
.sidebar-list-content, 
.sidebar-list-Accnt, 
.sidebar-list-admin, 
.sidebar-list-setting, 
.sidebar-list-payment {
    margin-top: 35px;
    margin-left: 42px !important;
}

.sidebar-responsive {
    position: fixed; 
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000; 
    background-color: var(--grey2);
    width: 317px;
    overflow-y: auto; 
}

.crossicon {
    position: absolute;
    left: 241px;
    top: 7px;
    background-color: white;
    width: 20px;
    height: 26px;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
}

@media screen and (min-width: 992px) {
    .crossicon {
        display: none;
    }
}

.dashbord img {
    color: red;
}

.barooom-text {
    width: 186px;
    height: 106px;
    position: absolute;
    top: 49px;
    left: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-family: "Maven Pro";
    font-size: 25px;
    font-weight: 500;
}

.dash-text,
.content-text,
.account-text,
.manag-text,
.setting-text,
.payment-text {
    display: inline-block;
    position: absolute; 
    margin-left: 36px;
    color: #ffffff;
    font-family: "Maven Pro";
    font-size: 19.5px;
    font-weight: 400;
}

.dash-icon,
.content-icon,
.admin-icon,
.manag-icon,
.setting-icon,
.payment-icon {
    width: 24px;
    height: 24px;
    position: absolute;
}

.anch-icon {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #ffffff;
}

.anch-icon img {
    margin-right: 10px;
}

.bold {
    font-weight: bold;
}

.sidebar-list-item {
    position: relative;
    /* padding-left: 20px; */
   padding-left: 8px;
}

.sidebar-list-item.active::before {
    content: "";
    position: absolute;
    left: -27px;
    top: -6px;
    bottom: 0;
    width: 6px;
    background-color: red;
    height: 40px;
}

.active .dash-text,
.active .content-text,
.active .account-text,
.active .manag-text,
.active .setting-text,
.active .payment-text {
    font-weight: bold;
    color: white;
}
