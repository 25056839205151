.about-btn {
    margin-top: 80px;
    margin-left: 341px;
}

.aboutsection {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .about-btn {
        margin: 171px 0px 0 -5px;

    }
    .aboutsection{
        width:100%;
    }
}