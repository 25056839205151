/* General Styles */
.acnt {
  margin: 292px 346px 0 0;
}
/* .account-mangmnet-container-whole{
  gap: 15px;
} */
.home-route {
  color: #222;
  font-family: "Maven Pro";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* .acnt-mangemnet1{
  margin: 100px 346px 0 0px !important;
} */

.input-container1 {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  background: #FFFFFF;
  outline: none;
}
.icons-open-close{
  justify-content: space-between;
  /* flex-wrap: wrap; */
}
.lock-img-input{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
}
.comn-inpt-property-account {
  max-width: 441px;
  width: 100%;
  height: 62px;
  outline: none;
  border: none;
  background: #FFFFFF;
  border-radius: 10px;
  text-indent: 20px;
}
.text-area-width{
 max-width: 100% !important;
}

.ElipsIcon3 {
  margin-top: 55px;
  height: 170px !important;
}

.elips-text {
  text-align: center;
  margin-top: 34px;
  color: #222;
  font-family: "Maven Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.elips-email {
  text-align: center;
  color: #5B5B5B;
  font-family: "Maven Pro";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.spcing-btween-grd {
  column-gap: 40px;
  width: 100%;
}

.your-bio {
  /* width: 100%; */
  height: 120px;
}

.same-icon-size {
  height: 30px;
  width: 30px;
  flex-shrink: 0;
}

.text-area-icon {
  margin-top: -95px;
}

.chnge-passcode {
  margin-top: 117px;
  color: #000;
}

.chnge-password {
  margin-top: 60px;
  text-align: left;
}

.chnge-password > p {
  color: #222;
  font-family: "Maven Pro";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pass-margin {
  margin-top: 5px;
}

.your-details {
  color: #222;
  text-align: left;
  font-family: "Maven Pro";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 33px;
  line-height: normal;
}

.butn-adjust {
  display: flex;
  justify-content: center;
  margin-top: 49px;
}

.line-color {
  margin-top: 28px;
  color: #A0A0A0;
}

.delet-account {
  margin-bottom: 63px;
  text-align: left;
  cursor: pointer;
}

.edt > .edit-icon {
  position: relative;
  margin: -60px 0 0 83px;
  z-index: 10000;
  background-color: white;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  padding: 11px;
  border: 1px solid;
}

/* Media Queries */
@media (max-width: 1200px) {
  .spcing-btween-grd {
    /* column-gap: 69px; */
    column-gap: 50px;
  }
}

@media (max-width: 320px) {
  .col-md-12.mb-3 .input-container1 textarea {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .col-md-12.mb-3 .input-container1 {
    width: 100%;
  }

  .col-md-12.mb-3 textarea {
    width: calc(100% - 40px);
  }

  .elips-text,
  .elips-email {
    margin-top: 0.5rem;
  }

  .butn-adjust {
    text-align: center;
  }

  .butn-adjust button {
    max-width: 217px;
  }

  .acnt-mangemnet1 {
    margin: 100px 20px 0 20px;
  }

  .comn-inpt-property {
    width: 100%;
  }

  .your-bio {
    width: 100%;
  }
  .account-mangmnet-container-whole{
    gap:0px !important;
  }
  .acnt-mangemnet1{
    margin: 0px !important;
  }
  
}