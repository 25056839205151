.est-details{
    margin-top:41px
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to change the transparency */
    z-index: 1050; /* Ensure the overlay is above the modal */
  }
  

    .modal-content-scroll{
        max-height: 70vh; /* Adjust height as needed */
        overflow-y: auto;
        padding: 1rem;
      }

      @media screen and (max-width:767px) {
        .allestablishment-details-table{
          margin-right: 20px !important;
        }
      }
  