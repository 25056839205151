
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  
  --very-lightgrey: #F6F6F6;
  --grey2:#fff;
  /* --ff-3333:#F33; */
  --crete-user-btn:#F33;

  /* --light-grey1:#ABABAB; */
  --light-grey1:#f33;
  --inputfiled:#EFEFEF;
  --ffcc-42: #FFCC42;
  
}