.grid-container {
    position: relative;
   
    height: 100vh; 
}

.main-content {
    margin-left: 317px; 
    height: 100%; 
    overflow-x: hidden; 
    background-image: url('../Assests/Bartender Dashboard.jpg');
    background-size: cover;
}

.main-container{
   
    margin: 46px 39px 0 18px;
   
}
@media screen and (max-width: 1920px) {
    .grid-container {
        grid-template-columns: 317px 1fr; 
    }
}

@media screen and (max-width: 992px) {
    .grid-container {
        display: block; 
    }

    .sidebar-responsive {
        position: fixed;
    }

    .main-content {
        margin-left: 0; 
    }
    .main-container{
        margin: 46px 39px 0 25px;
    }
}


@media screen and (max-width:767px){
    .main-container{
        margin: 46px 39px 0 7px;
    }
}

@media screen and (max-width:320px){
    .main-container{
        margin: 46px 39px 0 10px;
    }
    
}