.unique-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 80px;
    width: 80%;
    margin: 90px 0 0 108px;
  }

  .profile-image {
    width: 100%;
  }
  
  .right-section {
    display: flex;
    flex-direction: column;
    max-width: 913px;
  }
  
  .input-field {
    max-width: 913px;
    height: 50px;
    margin-bottom: 10px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #e1e1e1;
    outline: none;
    padding: 15px;
  }
  
  .crte-usr-btun {
    max-width: 913px;
    height: 50px;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 10px;
    color: #fff;
    border: none;
    /* border: 1px solid #000; */
    /* background-color: #fff; */
    background-color: #f33;
    /* margin-top: 57px; */
    margin-top:15px;
  }
  
  /* .crt-updt {
    margin: 30px 0 0 82px;
  } */
  .dtes{
    background-color: #FFFFFF !important;
    border-radius: 10px !important;
    border: 1px solid #e1e1e1;
    color:gray;
  }
  
  /* Media queries */
  @media screen and (max-width: 767px) {
    .unique-grid {
      grid-template-columns: 1fr;
      align-items: center;
      margin: 56px auto 0;
      width: 90%;
      padding: 0 20px;
      gap: 35px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .unique-grid {
      margin: 20px auto 0;
      width: 90%;
      padding: 0 20px;
    }
    .crte-usr-btun{
      margin-bottom: 20px;
    }
  }
  