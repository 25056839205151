
.brom {
  display: flex;
  justify-content: center;
  
  padding: 4px;
  align-items: center;
  color: #000;
  font-family: "Maven Pro";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 190px;
  height: 78px;
  margin-bottom: 20px;
  border-radius: none;
}

.welcome {
  text-align: center;
  color: #343434;
  font-family: "Maven Pro";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.email-display label,
.password-display label {
  display: block;
  margin-bottom: 10px;
  color: #222222;
  font-family: "Maven Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.password-display {
  margin-top: 25px;
}

.input-container,
.input-container-2 {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #C9D3DF;
  width: 100%;
  height: 62px;
  background: #F9F9F9;
}

.icon {
  margin-left: 10px;
}

.icon img {
  width: 24px;
  height: 24px;
  flex-shrink: 0; 
}


.Pass-input,.Email-inpt{
  padding: 10px;
  border: none;
  border-radius: 4px;
  width: 88%;
  box-sizing: border-box;
  background: #FFF;
  outline: none;
}
.keepmeloign{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

input::placeholder {
  color: rgba(92, 92, 92, 0.50);
  font-family: "Maven Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.password-toggle {
  margin-right: 17px;
}

.password-toggle img {
  width: 27px;
  height: 24px;
  cursor: pointer;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
}

.forgetPass {
  display: flex;
  margin-top: 25px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
 
}

.logged-in {
  color: #222;
  font-family: "Maven Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.forgetPassword {
  font-weight: 500;
  color: #f33;
  font-family: "Maven Pro";
  font-size: 18px;
  font-style: normal;
 
  line-height: normal;
}



.custom-container-sign{
  padding: 44px !important;
    width: 520px;
    height: 450px;
    max-width: 100% !important;
    max-height: auto;
    border-radius: 50px !important;
    font-family: "Maven Pro";

}
.custom-input-container-signup{
  flex-wrap: wrap;
}

.button1{
  width: 100% ;
  height: 50px;
  border-radius: 10px;
  border: none;
  background: #FFF;
  text-align: center;
  color: #343434;
  font-family: "Maven Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 40px;

  border-radius: 124px;
  font-weight: 600;
  background: #FFCC42;
}
.butn-contner{
  width: 100%;
  display: flex;
  justify-content:space-between;
  align-items: end;
}


.brom {
  margin: 0 auto; 
  width: fit-content; 
}

.Outer {
  height: 100vh;
  overflow-y: hidden;
  background-image: url('../CommonComp/Assests/login.jpg');
  /* margin-top: 131px; */
  background-size: cover;
}
.crd-brd{
  border: 5.241px solid var(--ffcc-42);
}

.custom-card {
  width: 90%;
  height: auto; 
  margin: 0 auto; 
}

.brom {
  width: 190px; 
  height: 78;
}

.welcome-text-frst {
  width: 100%; 
  
  font-size: 25px;
  font-weight: bold;
  font-family: "Maven pro";
  text-align: center;
}

.crd-bdy{
  max-width: 495px;
  width: 90%; 
  height: auto; 
  margin: 0 auto;
  padding: 10px; 
}
.adjusting-paddng{
  padding-top: 50px;
}


 .custom-logo-login{
  position: relative;
   
    margin-top: -99px;
    width: 130px;
 }



 @media (max-width: 768px) {
  .button1{
    margin-bottom: 80px;
  }
}


 @media  screen and (max-width: 576px) {
    .custom-container-sign {
      padding: 2px !important;
      border-width: 3px;
    }
    .welcome-text-frst h1 {
      font-size: 1.5rem;
    }
    .custom-input-container-signup {
     
      padding: 0px !important;
    }
    .butn-contner button {
      height: 50px;
    }
    .logged-in {
      font-size: 14px;
    }
    .forgetPassword{
      font-size: 14px;
    }
    .custom-logo-login{
      margin-top: -50px !important;
    }
  }

  
  @media screen and (max-width:992px) {
    
  .custom-container-sign{
    width: 500px;
   
    height: 465px;
        padding:2px  !important;
  }
    .custom-logo-login{
      margin-top: -45px
    }
    .welcome-text-frst{
      font-size: 22px !important;
    }

  }