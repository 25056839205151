.commoncard {
  display: flex;
  justify-content: space-evenly;
  padding: 8px 15px;
  border-radius: 10px;
  background-color:var(--grey2);
  color: black;
  height: 250px;
  flex-shrink: 0;
  color: #000;
box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.08);
text-align: center;
font-family: "Maven Pro";
/* font-size: 24px; */
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
cursor: pointer;
border: none;
}

.crd-icons {
  height: 60px;
  width: 60px;
}

/* Responsive adjustments */
@media (max-width:992px) {
  .commoncard {
    max-width: 100%; /* Set maximum width to full width for smaller screens */
    width: auto; /* Reset width to auto */
  }
}
