.estbname-adress {
  margin-top: 51px;
}

.estbname-adress p:first-child {
  font-family: "Maven Pro";
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.estbname-adress p:last-child {
  color: #000;
  font-family: "Maven Pro";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.three-btn {
 
  display: flex;
  flex-wrap: wrap;

  gap: 20px;
  margin-top: 34px;
}
.about-gig {
  border-radius: 10px;
  border: 1px solid var(--light-grey1) !important;
}
.comndesing-btn {
  color: #f33;

  width: 250px;
  height: 52px;
  flex-shrink: 0;
  border-radius: 10px;

  border: 1px solid var(--light-grey1);

  font-family: "Maven Pro";
}
.comndesing-btn.selected {
  background-color: var(--light-grey1);
  color: #ffffff !important;
}
