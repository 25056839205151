.header-design {
  border: 1px solid #e8e8e8;

  background: #f6f6f6;

  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  height: 105px;
}
.logoutDesign {
  border-radius: 10px;
  border: 1px solid #d6d6d6;
  background: #fff;
  margin-left: -27px !important;
}
.hamburger-icon {
  /* width: 30px; */
  width: 24px;
  height: auto;
  cursor: pointer;
}

.serch-bar {
  width: 600px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  outline: none !important;
  border-radius: 25px;
}

.welcome-text {
  display: flex;
  align-items: center;
  margin-top: 0px;
  color: #222;
  font-family: "Maven Pro";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.welcome-kate {
  color: #222;
  font-family: "Maven Pro";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.elips-wrap-contanr {
  display: flex;
}

.elips-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.hr-line {
  height: 1px;
  color: #9e9999;
  width: 98%;
  margin-left: 52px;
}

.notification-container {
  width: 390px;

  height: 350px;



  background-color: #ffffff;
  border-radius: 10px;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: absolute;
  right: 10px;
  top: 50px;
  z-index: 1000;
  border-radius: 20px;
  border: 1px solid #e3e3e3;

  background: #fff;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.notification-header h5 {
  margin: 0;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.notification-close {
  cursor: pointer;
  font-size: 30px;
}

.notification-list1 {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notification-item {
  display: flex;
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
}

.notification-icon {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.notification-content {
  flex-grow: 1;
}

.notification-info {
  display: flex;
  flex-direction: column;

  margin-bottom: 5px;
}

.notification-name {
  font-weight: 600;
}

.notification-time {
  color: #999;
  font-size: 12px;
}

.notification-text {
  color: #666;
}
.kate-deo{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Maven Pro';
}

@media (min-width: 992px) {
  .serch-bar {
    width: 350px;
  }
}
@media (min-width: 767px) {
  .serch-bar {
    width: 300px;
  }
}

@media (max-width: 767px) {
  .serch-bar {
    display: none;
  }
  .nav-serch-icon{
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .notification-container {
    width: 323px;
    top: 56px;
  }
}

@media screen and (max-width: 576px) {
  .notification-container {
     width: 310px;
     left: -110px;
      overflow-y: scroll !important;
  }
  .hr-line {
    display: none;
  }
  .header-design {
    background-color: var(--very-lightgrey);
    padding: 10px 0;
    height: 110px;
  }
  .kate-deo {
    font-size: 12px;
  }

  .elips-icon {
    width: 30px;
    height: 30px;
  }
  #notifi {
    width: 20px;
  }
  .navbar{
    padding-left: 10px !important;
  }
}
@media screen and (min-width: 1200px) {
  .serch-bar {
    width: 515px;
  }
}
@media screen and (max-width:480px) {
  .notification-container {
    width: 306px;
    /* left: -86px; */
    left: -64px;
     overflow-y: scroll !important;
 }
}