.crdtop {
  /* margin-top: 46px; */
  margin-top: 26px;
}
.main-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.crd-inner-cont {
  padding: 46px 0px 0px 34px;
}
.chrt-elemnt {
  flex-shrink: 0;
  background-color: var(--grey2);
  /* padding: 30px; */
  padding: 23px;
  top: 0;
  left: 0;
  position: relative;

  /* margin: 25px 43px 57px 17px; */
  margin:25px 43px 10px 17px; 
    min-height: 452px;
  border-radius: 10px;
}

.chrt-content {
  font-family: "Maven Pro", sans-serif;
}
.chrt-chk-list {
  display: flex;
  justify-content: space-between;
}
/* to be moved */

@media (min-width: 992px) {
  .main-cards .col-md-4 {
    width: calc(33.333% - 15px);
  }
}

@media (max-width: 992px) {
  .main-cards .col-md-4 {
    width: calc(50% - 15px);
  }
  .margin-25 {
    margin-top: 25px;
  }
}

@media (max-width: 767px) {
  .main-cards .col-md-4 {
    width: 100%;
    margin-bottom: 20px;
  }
}
