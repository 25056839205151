.searchbar-btnwrapper{
    display: flex;
    justify-content: space-between;
    margin-top: 94px;
    flex-wrap: wrap;
    margin-right: 18px;
    margin-left: 31px;
}

.add-subscription{
width: 347px;
height: 74px;
flex-shrink: 0;
border-radius: 10px;
background: #D9D9D9;
border: none;
font-family: "Maven Pro";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.manageplans{
    margin-top: 60px;
}
.icons-edit-dlt{
    display: flex;
    gap: 11px;
}
.icon1{
    width: 60px;
height: 60px;
flex-shrink: 0;
border-radius: 10px;
background: #D9D9D9;


}
.view-icon{
    display: flex;
    /* background: blueviolet; */
    width: 60px;
  cursor: pointer;
    height: 60px;
    vertical-align: sub;
    align-items: center;
    justify-content: space-around;
    background-color: #D9D9D9;
    border-radius: 10px;
}