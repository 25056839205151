
.acount-date{
    /* width: 631px; */
  width:536px;
  }
  .multi-select-con{
    cursor: pointer;
  }
  .account-status{
    color: #7E7E7E;

font-family: "Maven Pro";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }


.barom-serch{
    display: flex;
    align-items: center;
    border-radius: 100px;
    border: 1px solid #C9D3DF;
    background: #ffff;
    outline: none;
    /* width: 643px; */
    width: 550px;
    margin-top: 18px; 
  }
  .barom-serch-item{
    width:598px;
    height:45px;
    outline: none;
    border: none;
    background: #ffff;
    border-radius: 10px;
    text-indent: 10px;
  }
  .barom-app-text{
    font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
    margin-top: 48px;
  }

.custom-dropdown {
    position: absolute;
    top: calc(100% + 5px); 
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 5px;
  }
  
  .custom-dropdown .dropdown-item {
    padding: 10px;
  }
  
  .custom-dropdown .dropdown-item:hover {
    background-color: #ffff;
  }
  
  .custom-dropdown .form-label {
    font-weight: bold;
  }
  
  .custom-dropdown .form-select,.form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem white;
  
  }
  .form-select:focus{
    box-shadow: 0 0 0 0.25rem white;
  }
  input[type=date]:required:invalid::-webkit-datetime-edit {
    color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
}

   
  .custom-dropdown .custom-date-input {
    width: calc(100%);
  }
  
  select option {
    padding: 20px;
    /* margin: 40px; */
    background-color: #fff;
    color: #0e0e0e;
    /* text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); */
  }
 
  .custom-dropdown {
    position: absolute;
    top: calc(100% + 5px); 
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 5px;
  }
  
  .custom-dropdown .dropdown-item {
    padding: 10px;
  }
  
  .custom-dropdown .dropdown-item:hover {
    background-color: #ffff;
  }
  
  .custom-dropdown .form-label {
    font-weight: bold;
  }
  
  .custom-dropdown .form-select,.form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem white;
  
  }
  .form-select:focus{
    box-shadow: 0 0 0 0.25rem white;
  }
  input[type=date]:required:invalid::-webkit-datetime-edit {
    color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
}

   
  .custom-dropdown .custom-date-input {
    width: calc(100%);
  }
  
  select option {
    padding: 20px;
    /* margin: 40px; */
    background-color: #fff;
    color: #0e0e0e;
    /* text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); */
  }
  .mb-3.date-range-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
}

.date-field {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.date-field label {
    margin-bottom: 0.5rem;
}

  @media (max-width: 576px) {
    .barom-serch {
      width: 100% !important; 
      /* margin-left: -25px; */
      /* margin-left: -15px; */
      margin-left: -7px;
    }
    .acount-date {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    .custom-dropdown{
      width: 100%;
      /* margin-left: -17px; */
      margin-left: -8px;
    }
    .algn-top-bar{
      row-gap: 10px;
      
    }
    
  }
  
  @media (min-width: 576px) and (max-width: 768px) {
    .barom-serch {
      width: 80% !important; 
    }
    .acount-date {
      width: 80%; 
    }
    .custom-dropdown {
      width: 80%;
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .barom-serch {
      width: 100% !important; 
    }
    .acount-date {
      width: 100%; 
    }
    .custom-dropdown {
      width: 100%;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    .barom-serch {
      width:100% !important; 
    
    }
    .acount-date {
      width: 85%;
    }
    .custom-dropdown {
      width:100%;
    }
  }
  